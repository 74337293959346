import React from 'react';
import { Link } from 'gatsby';
import Slider from 'react-slick';
import BackgroundImage from 'gatsby-background-image';

import styles from './CategorizedPosts.module.scss';
import blueTriangle from '../../images/blue-triangle.svg';

export default class CategorizedPosts extends React.Component<any> {
  state: any;
  slider: any;
  constructor(props) {
    super(props);

    this.state = {
      activeCategory: this.categoriesList[0],
    };
  }

  get categoriesList() {
    const categories = [];
    this.props.posts.forEach((post) => {
      if (!categories.includes(post.node.frontmatter.category)) {
        categories.push(post.node.frontmatter.category);
      }
    });

    return categories;
  }

  get postsList() {
    const posts = [...this.props.posts];
    return posts.filter((post) => post.node.frontmatter.category === this.state.activeCategory);
  }

  render() {
    const sliderSettings = {
      speed: 500,
      slidesToShow: this.postsList.length < 4 ? this.postsList.length : 4,
      slidesToScroll: 1,
      nextArrow: <CustomArrow isNext={true} />,
      prevArrow: <CustomArrow />,
      infinite: false,
    };

    return (
      <section className={styles.screenBox}>
        <div>
          <div className={styles.categoriesWrapper}>
            <div className={styles.categoriesBox}>
              {this.categoriesList.map((category, i) => (
                <div
                  key={i}
                  className={
                    styles.category +
                    ' ' +
                    (this.state.activeCategory === category ? styles.active : '')
                  }
                  onClick={() => this.setState({ activeCategory: category })}
                >
                  <span data-number={'0' + (i + 1)}>{category}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Slider
          ref={(slider) => (this.slider = slider)}
          {...sliderSettings}
          className={styles.slickSlider + ' ' + 'blog-slider'}
        >
          {this.postsList.map((post, i) => (
            <Link to={post.node.fields.slug} key={i}>
              <div className={styles.postBox}>
                <BackgroundImage
                  className={styles.postPhoto}
                  Tag="div"
                  fluid={post.node.frontmatter.mainImage.childImageSharp.fluid}
                >
                  <span className={styles.postTitle}>{post.node.frontmatter.title}</span>
                </BackgroundImage>
                <p className={styles.postExcerpt}>{post.node.excerpt}</p>
                <span className={styles.viewBlog}>READ MORE</span>
              </div>
            </Link>
          ))}
        </Slider>
      </section>
    );
  }
}

function CustomArrow(props) {
  const { isNext, onClick } = props;
  return (
    <div
      style={{
        display: 'block',
        background: `url(${blueTriangle})`,
        height: '1.9vw',
        width: '1.9vw',
        transform: `rotate(${isNext ? 180 : 0}deg)`,
        top: 'calc(6vw - 0.95vw)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        position: 'absolute',
        left: `${isNext ? 'unset' : '-1.7vw'}`,
        right: `${isNext ? '-1.7vw' : 'unset'}`,
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  );
}
