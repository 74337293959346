import React from 'react';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import styles from './SecondScreen.module.scss';
import CategorizedPosts from './CategorizedPosts';

export default class SecondScreen extends React.Component<any> {
  state: any;
  constructor(props) {
    super(props);

    this.state = {
      activeCategory: this.categoriesList[0],
    };
  }

  get bottomBoxPosts() {
    const posts = [...this.props.posts];
    return posts.splice(3, 6);
  }

  get mobileViewPosts() {
    const posts = [...this.props.posts];

    if (this.state.activeCategory === 'recent') {
      return posts.slice(0, 3);
    } else {
      return posts
        .filter((p) => p.node.frontmatter.category === this.state.activeCategory)
        .slice(0, 3);
    }
  }

  get categoriesList() {
    const categories = [];
    this.props.posts.forEach((post) => {
      if (!categories.includes(post.node.frontmatter.category)) {
        categories.push(post.node.frontmatter.category);
      }
    });

    return categories;
  }

  render() {
    const { posts } = this.props;

    return (
      <section className={styles.screenBox}>
        <CategorizedPosts posts={posts}></CategorizedPosts>

        <div className={styles.desktopView}>
          <h2>recent articles</h2>
          <div className={styles.articlesBox}>
            <div className={styles.topBox}>
              {posts[0] ? (
                <Link
                  to={posts[0].node.fields.slug}
                  className={styles.article + ' ' + styles.firstArticle}
                >
                  <BackgroundImage
                    className={styles.backgroundImage}
                    Tag="div"
                    fluid={posts[0].node.frontmatter.mainImage.childImageSharp.fluid}
                  >
                    <div className={styles.topText}>
                      <div className={styles.articleTitle}>{posts[0].node.frontmatter.title}</div>
                      <p className={styles.postExcerpt}>{posts[0].node.excerpt}</p>
                      <span className={styles.readMore}>READ MORE</span>
                    </div>
                    <div className={styles.bottomText}>
                      <p>{posts[0].node.frontmatter.category}</p>
                      <p>{posts[0].node.frontmatter.date}</p>
                    </div>
                  </BackgroundImage>
                </Link>
              ) : null}
              <div>
                {posts[1] ? (
                  <Link to={posts[1].node.fields.slug} className={styles.article}>
                    <BackgroundImage
                      className={styles.backgroundImage}
                      Tag="div"
                      fluid={posts[1].node.frontmatter.mainImage.childImageSharp.fluid}
                    >
                      <div className={styles.topText}>
                        <div className={styles.articleTitle}>{posts[1].node.frontmatter.title}</div>
                        <span className={styles.readMore}>READ MORE</span>
                      </div>
                      <div className={styles.bottomText}>
                        <p>{posts[1].node.frontmatter.category}</p>
                        <p>{posts[1].node.frontmatter.date}</p>
                      </div>
                    </BackgroundImage>
                  </Link>
                ) : null}
                {posts[2] ? (
                  <Link to={posts[2].node.fields.slug} className={styles.article}>
                    <BackgroundImage
                      className={styles.backgroundImage}
                      Tag="div"
                      fluid={posts[2].node.frontmatter.mainImage.childImageSharp.fluid}
                    >
                      <div className={styles.topText}>
                        <div className={styles.articleTitle}>{posts[2].node.frontmatter.title}</div>
                        <span className={styles.readMore}>READ MORE</span>
                      </div>
                      <div className={styles.bottomText}>
                        <p>{posts[2].node.frontmatter.category}</p>
                        <p>{posts[2].node.frontmatter.date}</p>
                      </div>
                    </BackgroundImage>
                  </Link>
                ) : null}
              </div>
            </div>
            <div className={styles.bottomBox}>
              {this.bottomBoxPosts.map((post, i) => (
                <Link to={post.node.fields.slug} key={i} className={styles.article}>
                  <BackgroundImage
                    className={styles.backgroundImage}
                    Tag="div"
                    fluid={post.node.frontmatter.mainImage.childImageSharp.fluid}
                  >
                    <div className={styles.topText}>
                      <div className={styles.articleTitle}>{post.node.frontmatter.title}</div>
                      <Link to="https://clickspray.com" className={styles.readMore}>
                        READ MORE
                      </Link>
                    </div>
                    <div className={styles.bottomText}>
                      <p>{post.node.frontmatter.category}</p>
                      <p>{post.node.frontmatter.date}</p>
                    </div>
                  </BackgroundImage>
                </Link>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.mobileView}>
          <div className={styles.grayTriangle}></div>
          <div className={styles.heading}>featured articles</div>
          <div className={styles.categoriesBox}>
            {this.categoriesList.map((category, i) => (
              <div
                key={i}
                className={
                  styles.category +
                  ' ' +
                  (this.state.activeCategory === category ? styles.active : '')
                }
                onClick={() => this.setState({ activeCategory: category })}
              >
                <span className={styles.triangle}></span>
                <span>{category}</span>
              </div>
            ))}
            <div
              className={
                styles.category +
                ' ' +
                (this.state.activeCategory === 'recent' ? styles.active : '')
              }
              onClick={() => this.setState({ activeCategory: 'recent' })}
            >
              <span className={styles.triangle}></span>
              <span>recent</span>
            </div>
          </div>
          <div className={styles.postsBox}>
            {this.mobileViewPosts.map((post, i) => (
              <Link to={post.node.fields.slug} key={i} className={styles.article}>
                <div className={styles.postBox}>
                  <BackgroundImage
                    className={styles.postPhoto}
                    Tag="div"
                    fluid={post.node.frontmatter.mainImage.childImageSharp.fluid}
                  >
                    <span className={styles.postTitle}>{post.node.frontmatter.title}</span>
                  </BackgroundImage>
                  <p className={styles.postExcerpt}>{post.node.excerpt}</p>
                  <span className={styles.readMore}>READ MORE</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    );
  }
}
