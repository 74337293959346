import React from 'react';
import { Link } from 'gatsby';

import styles from './FirstScreen.module.scss';

export default class BlogPage extends React.Component<any> {
  get featuredPost() {
    return this.props.posts.find((p) => p.node.frontmatter.isFeatured);
  }

  render() {
    return (
      <section className={styles.screenBox}>
        <div className={styles.grayTriangle}></div>
        <div className={styles.textWrapper}>
          <span className={styles.category}>{this.featuredPost?.node.frontmatter.category}</span>
          <div className={styles.flexSpaceBetween}>
            <div>
              <h6>
                FEATURED <span className={styles.desktopText}>BLOG POST</span>
              </h6>
              <h2>{this.featuredPost?.node.frontmatter.title}</h2>
              <p className={styles.postExcerpt}>{this.featuredPost?.node.excerpt}</p>
            </div>
            <Link to={this.featuredPost?.node.fields.slug} className={styles.viewBlog}>
              <span className={styles.mobileText}>LEARN MORE</span>
              <span className={styles.desktopText}>VIEW BLOG</span>
            </Link>
          </div>
        </div>
        <div className={styles.whiteLine}></div>
        <div className={styles.whiteLine + ' ' + styles.whiteLineBottom}></div>
      </section>
    );
  }
}
